import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import LoginContainer from '~/components/containers/login/login'
import Loading from '~/components/Loading'
import RenderHeadTitle from '~/components/RenderHeadTitle'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { DTRACK_HOSTS, VIETFISH_HOSTS } from '~/utils/constants'
function LoginIndex({ user }) {
  const { t } = useTranslation('login')
  const router = useRouter()
  useEffect(() => {
    if (user?.token?.access_token) {
      router.push('/')
    }
  })
  if (!user?.token?.access_token) {
    return (
      <>
        <RenderHeadTitle headTitle={t('headTitle')} headTitleVF={t('headTitleVF')} />
        <LoginContainer />
      </>
    )
  } else {
    return <Loading />
  }
}

export const getServerSideProps = async (context) => {
  const host = context?.req?.headers?.host
  const { locale } = context
  const props = {}

  if (DTRACK_HOSTS.includes(host)) {
    props.metaTags = {
      title: 'DTRACK ASIA',
      desc: 'Nền tảng cung cấp dịch vụ cho doanh nghiệp hàng đầu Việt Nam',
      image: '/assets/png/seo.png',
    }
  } else if (VIETFISH_HOSTS.includes(host)) {
    props.metaTags = {
      title: 'Vietfish Expo',
      desc: 'VietFish là hội chợ giúp gian hàng của bạn lên sàn thương mại điện tử nhanh chóng và chuyên nghiệp',
      image: '/assets/jpeg/vietfish_logo.jpeg',
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['login', 'common', 'defaultLayout'])),
    },
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, null)(LoginIndex)
