import { faChevronLeft, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'antd'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { compose } from 'redux'
import LoginBanner1 from '~/assets/svg/login_banner1.svg'
import LoginBanner2 from '~/assets/svg/login_banner2.svg'
import LoginBanner3 from '~/assets/svg/login_banner3.svg'
import LoginBanner4 from '~/assets/svg/login_banner4.svg'
import LoginBanner5 from '~/assets/svg/login_banner5.svg'
import Footer from '~/components/Footer'
import LoginHeader from '~/components/LoginHeader'
import RenderByPlatform from '~/components/RenderByPlatform'
import { SignupForm } from '~/components/UserForm'
import { withTranslation } from '~/i18n'
import { getWhoamiAction, loginAction, signUpAction } from '~/redux/actions/Authentication'
import { getProducerAction } from '~/redux/actions/Producer'
import { DTRACK, FOODMAP, VIETFISH } from '~/utils/constants'
// import './styles.scss'
function Login({ t, signUp, platform, login, getWhoami, getProducer }) {
  const router = useRouter()
  const [signUpFailure, setSignUpFailure] = useState(null)

  const onSignUp = (values) => {
    signUp(
      values,
      () => {
        setSignUpFailure(false)
        login(
          {
            username: values.email,
            password: values.password,
            remember: true,
          },
          () => {
            getWhoami((user) => {
              if (!user?.roles?.length > 0) {
                getProducer()
                router.push(platform === VIETFISH ? '/register-department' : '/')
              }
            })
          },
        )
      },
      () => setSignUpFailure(true),
    )
  }

  const SlickArrowLeft = ({ ...props }) => (
    <button {...props}>
      <FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronRight} />
    </button>
  )
  const SlickArrowRight = ({ ...props }) => (
    <button {...props}>
      <FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronLeft} />
    </button>
  )
  const dtrackSettings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  }
  const vietfishSettings = {
    dots: true,
    infinite: false,
    fade: true,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  }
  const renderTitle = () => {
    const titles = {
      [DTRACK]: `Chào mừng bạn đến với DTrack.asia`,
      [VIETFISH]: t('vietfish-welcome-title'),
      [FOODMAP]: `Chào mừng bạn đến với Foodmap Insight`,
    }

    return titles?.[platform] || ''
  }
  const renderDescription = () => {
    const descriptions = {
      [DTRACK]: `Dtrack - Nền tảng cung cấp dịch vụ Nông nghiệp hàng đầu Việt Nam`,
      [VIETFISH]: t('vietfish-welcome-desc'),
      [FOODMAP]: `Foodmap Insight - Nền tảng cung cấp dịch vụ Nông nghiệp hàng đầu Việt Nam`,
    }

    return descriptions?.[platform] || ''
  }

  const renderSliderIntro = () => {
    switch (platform) {
      case DTRACK:
        return (
          <Slider {...dtrackSettings} dotsClass="slick-dots" className="slider">
            <div className="slider-item">
              <LoginBanner1 height={400} width={456} />
              <span className="title">Bạn chưa biết bán hàng online</span>
              <span className="description">Yên tâm {platform} xử hết</span>
            </div>
            <div className="slider-item">
              <LoginBanner2 />
              <span className="title">Bạn cần truy xuất nguồn gốc sản phẩm</span>
              <span className="description">{platform} cung cấp miễn phí cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner3 />
              <span className="title">
                Sản phẩm bạn cần bao bì, hình ảnh đẹp thu hút người dùng
              </span>
              <span className="description">{platform} sẽ tư vấn, thiết kế và in ấn cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner4 />
              <span className="title">Bạn cần tư vấn các chứng chỉ nông nghiệp</span>
              <span className="description">Chuyên gia của {platform} sẽ hướng dẫn cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner5 />
              <span className="title">Bạn muốn tư vấn các vấn đề pháp lý</span>
              <span className="description">
                Đội ngũ {platform} sẽ giải đáp mọi thắc mắc cho bạn
              </span>
            </div>
            <div className="slider-item">
              <img src="/assets/png/login_banner6.png" width={456} />
              <span className="title">Bạn cần thêm chi phí kinh doanh?</span>
              <span className="description">{platform} sẵn sàng hỗ trợ cho bạn</span>
            </div>
          </Slider>
        )
      case VIETFISH:
        return (
          // <Slider {...vietfishSettings} dotsClass="slick-dots" className="slider">
          <img
            style={{ width: 650, marginTop: 10 }}
            src={`assets/jpg/vietfish_banner_${router.locale === 'vi' ? 'vi' : 'en'}.jpg`}
          />
          // </Slider>
        )
      case FOODMAP:
        return (
          <Slider {...dtrackSettings} dotsClass="slick-dots" className="slider">
            <div className="slider-item">
              <LoginBanner1 height={400} width={456} />
              <span className="title">Bạn chưa biết bán hàng online</span>
              <span className="description">Yên tâm {platform} xử hết</span>
            </div>
            <div className="slider-item">
              <LoginBanner2 />
              <span className="title">Bạn cần truy xuất nguồn gốc sản phẩm</span>
              <span className="description">{platform} cung cấp miễn phí cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner3 />
              <span className="title">
                Sản phẩm bạn cần bao bì, hình ảnh đẹp thu hút người dùng
              </span>
              <span className="description">{platform} sẽ tư vấn, thiết kế và in ấn cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner4 />
              <span className="title">Bạn cần tư vấn các chứng chỉ nông nghiệp</span>
              <span className="description">Chuyên gia của {platform} sẽ hướng dẫn cho bạn</span>
            </div>
            <div className="slider-item">
              <LoginBanner5 />
              <span className="title">Bạn muốn tư vấn các vấn đề pháp lý</span>
              <span className="description">
                Đội ngũ {platform} sẽ giải đáp mọi thắc mắc cho bạn
              </span>
            </div>
            <div className="slider-item">
              <img src="/assets/png/login_banner6.png" width={456} />
              <span className="title">Bạn cần thêm chi phí kinh doanh?</span>
              <span className="description">{platform} sẵn sàng hỗ trợ cho bạn</span>
            </div>
          </Slider>
        )
      default:
        return <></>
    }
  }

  const renderVietFishDownloadButtons = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 12, marginTop: 10, width: 200 }}>
        <Button type={'primary'}>
          <a target="_blank" href={`/assets/files/${router.locale || 'en'}/dkgh.docx`}>
            <FontAwesomeIcon icon={faDownload} width={22} />
            &nbsp;{t('registerBoothForm')}
          </a>
        </Button>
        <Button type={'primary'}>
          <a target="_blank" href={`/assets/files/${router.locale || 'en'}/dktt.docx`}>
            <FontAwesomeIcon icon={faDownload} width={22} />
            &nbsp;{t('sponsorForm')}
          </a>
        </Button>
        <Button type={'primary'}>
          <a target="_blank" href={`/assets/files/${router.locale || 'en'}/map.pdf`}>
            <FontAwesomeIcon icon={faDownload} width={22} />
            &nbsp;{t('map')}
          </a>
        </Button>
      </div>
    )
  }

  return (
    <div className="login-page">
      <LoginHeader />
      <div className="login-body-wrapper">
        <Row className="container" justify={platform === FOODMAP ? 'center' : 'start'}>
          <Col lg={14}>
            <div className="intro-wrapper">
              <div
                className="intro-content"
                {...(platform === FOODMAP && { style: { textAlign: 'center' } })}
              >
                <div className="intro-title">{renderTitle()}</div>
                <div className="intro-description">{renderDescription()}</div>
              </div>
              {renderSliderIntro()}
              {platform === VIETFISH && <div>{renderVietFishDownloadButtons()}</div>}
            </div>
          </Col>
          {platform !== FOODMAP && (
            <Col className="signup-form-wrapper" lg={10} xs={24}>
              <div className="title">{t('sign-up')}</div>
              <div className="description">
                {t('sign-up-desc')} {platform}.
              </div>
              <SignupForm t={t} onSubmit={onSignUp} />
              {signUpFailure && <div className="error-text">{t('phoneOrEmailIsTakenErr')}</div>}
              {signUpFailure != null && !signUpFailure && (
                <div className="success-text">{t('signUpSuccess')}</div>
              )}
            </Col>
          )}
        </Row>
      </div>
      <RenderByPlatform name={'footer'}>
        <Footer />
      </RenderByPlatform>
    </div>
  )
}

const mapStateToProps = (state) => ({
  platform: state.layout.platform?.platform,
})
const mapDispatchToProps = (dispatch) => ({
  signUp: (payload, actionSuccess, actionFailure) =>
    dispatch(signUpAction(payload, actionSuccess, actionFailure)),
  login: (payload, actionSuccess, actionFailure) =>
    dispatch(loginAction(payload, actionSuccess, actionFailure)),
  getWhoami: (actionSuccess) => dispatch(getWhoamiAction(actionSuccess)),
  getProducer: () => dispatch(getProducerAction()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('login'),
)(Login)
